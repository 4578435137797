@import "../../../../styles/vars";

.wrapper {
  width: 100%;
}
.menuPanel {
  position: fixed;
  width: 100%;
  bottom: 0;
  //left: 50%;
  //transform: translateX(-50%);
  overflow: visible;
  z-index: 2;
}

.wrapper {
  width: 100%;
  position: relative;
}
