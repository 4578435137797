.modal {
    &_wrapper {

    }

    &_content {
        background-color: rgba(49, 49, 49, 0.47) !important;
        backdrop-filter: blur(20px);

    }
    &_header {
        background-color: transparent !important;
        & * {
            color: white !important;
        }
    }
}

.input {
    background-color: transparent !important;
    color: #ffffff;
    height: 40px;
    border-radius: 20px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.51) !important;
    }
}
.btn {
    width: 100%;
    display: flex;
    justify-content: center;
    & button {
        width: 100%;
    }
}