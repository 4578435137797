.way {
    width: 100%;
    height: 20px;
    margin-top: 1rem;
    border-radius: 3px;
}

.divider {
    & span {
        display: flex;
        align-items: center;
        gap:5px;
        opacity: .5 !important;
        & * {
            font-size: .8rem;
        }
        & svg, & svg * {
            width: 1.2rem;
        }
    }

}
