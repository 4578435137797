@import "src/styles/vars";

.sidebar {
    position: absolute;
    //z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    color: white;

    &_wrapper {
        background-color: $dark-secondary-color;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        width: 100%;
        position: absolute;
        left: 0;
        padding-left: calc((100vw - (32px * 5)) / 10);
        padding-right: calc((100vw - (32px * 5)) / 10);
        padding-top: 10px;
        transform: translateY(-100%);
        animation: slider-in .2s ease-out forwards;
    }

    &_overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        opacity: 0;
        z-index: 0;
        animation: fadeIn .2s ease-out forwards;
    }

    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        &.noTitle {
            justify-content: flex-end;
        }
    }

}

.fadeOut {
    animation: fadeOut .2s ease-out forwards;
}

.sliderOut {
    animation: slider-out .2s ease-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slider-in {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slider-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
