@import "src/styles/vars";

$base-height: 448px;
$base-font-size: 5rem;
.game {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    overflow: visible;
    gap: 20px;
    & * {
        overflow: visible !important;
    }
    &_button {
        background-color: $light-selected-primary-color;
        width: 100%;
        height: 45px;
        border-radius: 20px;

    }

    &_buttons {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        & * {
            overflow: visible !important;
        }
        & button:nth-child(2) {
            width: 45px;
            border-radius: 50%;
            min-width: 45px !important;
        }
    }
}

.timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
        font-size: 2.5rem;
        color: #fff;
    }


    & > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        padding:    0 5px;
        width: 100%;
        //background-color: rgba(16, 12, 45, 0);
        //backdrop-filter: blur(3px);
        border-radius: 16px;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 16px;
            padding: 1px;
            background: linear-gradient(90deg, #678AFF 0%, rgba(4, 2, 21, 0) 50%, #678AFF 100%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: 15px;
            background-color: rgba(16, 12, 45, 0.5);
            backdrop-filter: blur(5px);
            z-index: -2;
        }
    }

    &_first {
        //line-height: .8;
        &_default {
            color: white;
        }
        &_red {
            color: #ff0000 !important;
        }
    }

    &_first {
        font-size: 2.8rem;
        font-weight: 400;

    }

    &_second {
        width: 30px;
    }


}

.light {
    &.timer {
        //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.dark {
    &.timer {
        background-color: transparent;
        box-shadow: none;
        //background-color: rgba(34, 34, 34, 0.6);
        //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
    & p {
        color: white;
    }
}
.animatedButton8 {
    cursor: pointer;
    background: transparent;
            //linear-gradient(-30deg, #0053b3 50%, #003880 50%);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-decoration: none;
    transform: translate(0%, 0%);
    overflow: hidden;
    color: #ffffff;
    position: relative;
   // border-radius: 20px;
    width: 100%;
    height: 45px;

    &_content {

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 10px 20px;
        color: white;
        font-size: 16px;
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.1), 0 0 5px rgba(0, 0, 255, 0.5);
        transition: all 0.3s ease;
        cursor: pointer;
        background: radial-gradient(56% 81.48% at 50% 100%, #C9C4FF 0%, #003CFF 100%);
    }
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #8599ad;
        opacity: 0;
        transition: .2s opacity ease-in-out;
    }

    &:hover::before {
        opacity: 0.2;
    }

    span {
        position: absolute;
    }

    span:nth-child(1) {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to left, rgba(8, 26, 43, 0), #2680d9);
        animation: animateTop 2s linear infinite;
    }

    span:nth-child(2) {
        top: 0px;
        right: 0px;
        height: 100%;
        width: 2px;
        background: linear-gradient(to top, rgba(8, 26, 43, 0), #2680d9);
        animation: animateRight 2s linear -1s infinite;
    }

    span:nth-child(3) {
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, rgba(8, 26, 43, 0), #2680d9);
        animation: animateBottom 2s linear infinite;
    }

    span:nth-child(4) {
        top: 0px;
        left: 0px;
        height: 100%;
        width: 2px;
        background: linear-gradient(to bottom, rgba(8, 26, 43, 0), #2680d9);
        animation: animateLeft 2s linear -1s infinite;
    }
}




//@media (max-height: 590px) {
//    .timer {
//        &_first {
//            font-size: 5rem;
//        }
//    }
//}
//
//@media (max-height: 500px) {
//    .timer {
//        &_first {
//            font-size: 4.24rem;
//        }
//    }
//}
//
//@media (min-height: 700px) and (max-height: 930px) {
//    .timer {
//        &_first {
//            font-size: 6.93rem;
//        }
//    }
//
//}
//
//@media (min-height: 930px) {
//    .timer {
//        &_first {
//            font-size: 7.89rem;
//        }
//
//    }
//}
