@import "src/styles/vars";

.modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: white;

    &_overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        opacity: 0;
        animation: fadeInOverlay .2s ease-out forwards;
        &.overlayOut {
            animation: fadeOutOverlay 0.2s ease-out forwards;
        }
    }

    &_wrapper {
        position: absolute;
        width: 90%;
        background-color: $dark-secondary-color;
        border-radius: 16px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.2);
        animation: fadeInWrapper .2s ease-out forwards;
        padding: 16px;
        &.wrapperOut {
            animation: fadeOutWrapper 0.2s ease-out forwards;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 16px;
            padding: 1px;
            background: linear-gradient(90deg, rgba(103, 138, 255, 0.30) 0%, rgba(4, 2, 21, 0) 10%,rgba(4, 2, 21, 0) 90%, rgba(103, 138, 255, 0.30) 100%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: 15px;
            background-color: rgba(16, 12, 45, 0.5);
            backdrop-filter: blur(5px);
            z-index: -2;
        }
    }

    &_cross {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}




@keyframes fadeInOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInWrapper {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes fadeOutOverlay {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeOutWrapper {
    from {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
    }
}
