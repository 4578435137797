.body {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 1.5rem;

    &_row {
        width: 100%;
        height: 70px;
        border-radius: 10px;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &_info {
        display: flex;
        align-items: center;
        gap: 10px;

        &_box {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        &_name, &_position  {
            font-size: 1.2rem;
            font-weight: 400;
            color: white;
            display: inline-flex;
            gap: 5px;
            align-items: center;
        }
        &_link {
            opacity: 0.8;
        }
        &_balance {
            font-size: .8rem;
            color: white;
            font-weight: 200;
            opacity: .5;
        }

        &_done {
            & * {
                color: #43a047;
            }
        }

    }
}
