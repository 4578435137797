@import "vars";
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    user-select: none;
    font-weight: 300;
    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;

}



.center {
  padding: 0 $center-padding;
}

.dark {
  background-color: $dark-primary-color;
}

button {
    outline: none;
    border: none;
}
input {
    all: unset; /* Сбрасывает все стили до базовых */
    box-sizing: border-box; /* Устанавливает корректное определение размера */
    appearance: none; /* Убирает стандартное оформление на мобильных устройствах */
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

.custom-snackbar {
  & .notistack-MuiContent-success,& .notistack-MuiContent-error, & .notistack-MuiContent-info {
    background-color: rgba(37, 37, 37, 0.65);
    backdrop-filter: blur(3px);
  }
  & .notistack-MuiContent-success {
    & * {
      color: #43a047;
    }
  }

  & .notistack-MuiContent-error {
    & * {
      color: #d32f2f;
    }
  }

    & .notistack-MuiContent-info {
        & * {
            color: rgba(42, 129, 255, 0.91);
        }
    }



}

.btn {
    &_primary {
        border-radius: 20px;
        background-color: #6689fd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-width: 50%;
        color: #fff;
        padding: 5px;
        height: 35px;
        transition: .3s background-color;
        &:hover {
            background-color: #5672da;
        }
        &:active {
            background-color: #6c8cfd;

        }


        &-outlined {
            border-radius: 20px;
            background-color: #181a43 !important;
            border: 1px solid #6689fd;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            min-width: 50%;
            color: #fff;
            padding: 5px;
            height: 35px;
            transition: .3s background-color;
            &:hover {
                background-color: #202159 !important;
            }
            &:active {
                background-color: #181a43 !important;

            }
        }
    }
}


.fade-in {
  opacity: 0;
  animation: fadeIn .3s ease-out forwards;
}

.ant-modal-close {
    & * {
        color: #fff !important;
    }
}


.ant-drawer-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #1c1c1e !important;

}

.ant-drawer {
    z-index: 999 !important;
}

.cross {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background-color: #373737;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.14);
    padding: 3px;
    & * {
        color: #858585 !important;
    }
}

.golden {
    background-image: linear-gradient(225deg, #ffc966,  #ffc814);
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.share-tech-mono-regular {
    & * {
        font-family: "Share Tech Mono", monospace;
        font-weight: 400;
        font-style: normal;
    }
}

.blue-gradient-border {

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

