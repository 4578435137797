.earn {
        width: 100%;
        height: 100vh;
        overflow: auto;
        padding-top: 20px;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
}
