@import "src/styles/vars";
.sidebar {
    &_mask {
        backdrop-filter: blur(5px);
    }

    &_content {
        background-color: #141414 !important;
        & * {
            color: white;
        }
    }
    &_wrapper {
        max-height: 90vh !important;

    }
    &_body {
        //padding-top: 0 !important;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
.button {
    background-color: $light-selected-primary-color;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    height: fit-content;

    &_title {
        font-size: 2rem;
    }

    &_img {
        border-radius: 20px;
    }

    &_item {
        width: 100%;
        height: 50px;
        background-color: $dark-contrast-background;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        cursor: pointer;

        &__cost {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        &__gif {
            width: 24px;
        }
    }


}
