@import "../../../../../styles/vars";
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  //padding-bottom: 20px;
  overflow: hidden;
  background-color: #09061e;




  &_item {
    cursor: pointer;

    &_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

  }

    &_box {
        display: flex;
        align-items: center;
        flex-direction: column;
        &__paragrah {
            font-size: .7rem;
            font-weight: 300;
        }
    }

}


.light {
  &.menu {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: visible;

    &_item {
      &_wrapper {
        &:not(&_active):hover {
          & svg *, & p {
            color: #6e6e6e;
            fill: #6e6e6e;
          }
        }

        & * {
          color: #858585;
        }
        &_active {
          //background-color: $light-selected-primary-background;
          & svg *, & p{
            color: $light-selected-primary-color;
            fill:  $light-selected-primary-color
          }
        }
      }

    }


  }
}
.dark {
  &.menu {
    //background-color: #222222;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

    &_item {
      &_wrapper {
        &:not(&_active):not(&_animated):hover {
          & svg *, & p {
            color: #6e6e6e;
            fill: #6e6e6e;
          }
        }

        &:not(&_animated) * {
          color: rgba(133, 133, 133, 0.61);
          fill: rgba(133, 133, 133, 0.61);
        }

        &_active:not(&_animated) {
          // background-color: $dark-selected-primary-background;
          & svg *, & p {
            color: $dark-selected-primary-color;
            fill: $dark-selected-primary-color;
          }
        }

        //&_active.&_animated {
        //  & svg *, & p {
        //    color: $dark-selected-primary-color;
        //  }
        //}

        &_animated {
          & p {
            color: #ffffff;
          }
        }

      }

    }


  }
}
