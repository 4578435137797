.body {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .7rem;

    &_tasks {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__row {
            width: 100%;
            height: 60px;
            background-color: #329edf;
            border-radius: 10px;
            cursor: pointer;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
            &-title {
                color: white;
            }
            &-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
            }
            &-award, &-done {
                display: flex;
                align-items: center;
                gap: 5px;
                &, & p {
                    color: #acd3ff;
                    font-size: .7rem;
                }

            }
            &-img {
                width: 50px;
                border-radius: 10px;
            }
        }
    }
}
