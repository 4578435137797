@import "src/styles/vars";
.wrapper {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    & > p {
        font-size: 2rem;
        font-weight: 600;
    }
}
.hill {
    width: 100%;
    background-color: $dark-selected-primary-background;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(90deg, rgba(103, 138, 255, 0.30) 0%, rgba(4, 2, 21, 0) 10%,rgba(4, 2, 21, 0) 90%, rgba(103, 138, 255, 0.30) 100%);
        -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-radius: 16px;
        background-color: $dark-selected-primary-background;
        backdrop-filter: blur(5px);
        z-index: -2;
    }


    &_body {
        width: 100%;

    }

    &_background {
        & img {
            position: absolute;
        }

        //& img:nth-child(1) {
        //    left: 50%;
        //    top: 18%;
        //    width: 60px;
        //    transform: rotate(-30deg);
        //}
        //& img:nth-child(2) {
        //    left: 59%;
        //    width: 140px;
        //    top: -5%;
        //}
        & img:nth-child(1) {
            width: 60px;
            right: 0;
            top: 0;
            transform: translate(20%, -40%);
        }
    }

    &_info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
    }


    &_about {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 2.5px;
        & > p {
            font-weight: 400;
            //font-size: 1.2rem;
        }

    }

    &_timer {
        padding: 2.5px 5px;
        background-color: white;
        border-radius: 8px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        & p {
            color: black;
            font-weight: 500;
            font-size: .8rem;
        }
    }

}

.button {
    background: radial-gradient(56% 81.48% at 50% 100%, #FFFFFF 0%, #E09215 100%);
    width: 100%;
    height: 40px;
    border-radius: 16px;
    z-index: 2;
}