.circular {
    &_box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & * {
            overflow: visible;

        }

    }
}

.skeleton {
    width: 100%;
    height: 100%;
    min-height: 15px;
    position: relative;
    overflow: hidden;
    border-radius: inherit;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.02) 25%, rgba(0, 0, 0, 0.05) 37%, rgba(0, 0, 0, 0.02) 63%);
        animation-iteration-count: infinite;
    }
}
.light {
    &.skeleton {
        &::after {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.02) 25%, rgba(0, 0, 0, 0.05) 37%, rgba(0, 0, 0, 0.02) 63%);
            background-size: 400% 100%;
        }
    }
}
.dark {
    &.skeleton {
        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.02) 25%, rgba(255, 255, 255, 0.09) 37%, rgba(255, 255, 255, 0.02) 63%);
            background-size: 400% 100%;
        }
    }
}



@keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}
