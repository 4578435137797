@import "src/styles/vars";

.divider {
    width: 100%;
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;

    &.left {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &::before, &::after {
        content: '';
        position: relative;
        height: 1px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.11);
    }

    &.left {
        justify-content: flex-start;

        &::before {
            width: 5%;
        }

        &::after {
            width: 95%;
        }
    }

    &.center {
        justify-content: center;

        &::before, &::after {
            width: 50%;
        }
    }

    .divider_span {
        color: white;
        padding-right: 10px;
        padding-left: 10px;
        min-width: fit-content;
        white-space: nowrap;
        opacity: .9;
        font-weight: 200;
        font-size: .9rem;
    }
}
