.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    & * {
        color: white;
    }
    & p {
        font-size: 1.5rem;
    }
}
