.sidebar {
    &_wrapper {
        height: 70vh;
    }

}

.content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &_awards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 1rem;
        margin-bottom: 3rem;

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #383838;
            border-radius: 10px;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            position: relative;

            &-active {
                border: 1px solid gold;
                cursor: pointer;
            }

            &-collected {
                width: 100%;
                height: 100%;
                backdrop-filter: blur(3px);
                background-color: rgb(4, 2, 21);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                border-radius: inherit;
                & * {
                    color: #43a047;

                    & svg, & svg * {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            & p {
                font-size: .8rem;
                opacity: .7;
            }

        }

        &__logo {
            & * {
                width: 35px;
                height: 35px;
            }
        }

    }

    &_btn {
        width: 100%;
        height: 50px;
        position: fixed;
        bottom: 1rem;
        z-index: 9;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 -10px 20px 0 #00000061;
    }
}

.item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 3px;
    border-radius: 10px;
    padding: 5px;
    & img {
        width: 60px;
    }

    & > p {
        font-size: .9rem;
    }

    & > span {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        & > p {
            font-size: .8rem;
        }
    }
    &_collected {
        background-color: #040215;
        position: relative;
        overflow: visible;
        z-index: 0;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 10px;
            padding: 1px;
            background: linear-gradient(90deg, rgba(103, 138, 255, 0.30) 0%, rgba(4, 2, 21, 0) 20%,rgba(4, 2, 21, 0) 80%, rgba(103, 138, 255, 0.30) 100%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: 10px;
            background-color: #040215;
            backdrop-filter: blur(5px);
            z-index: -2;
        }
    }

    &_active, &_notactive {
        background: linear-gradient(135deg, #99d8ff, #cb9fff, #ff9ff3);
    }

    &_active {
        cursor: pointer;
        &_text {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            border-radius: 5px;
            background-color: #fd9c00;
        }
    }

    &_notactive {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.68);
            border-radius: inherit;
            backdrop-filter: blur(1px);
        }
    }
}
