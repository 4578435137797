.avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    border-radius: inherit;
    opacity: 1;
    transition: .2s linear;
    &_wrapper_loaded {
        opacity: 0 !important;
    }
    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            //border-radius: 50%;
            transition: .2s linear;
            opacity: 0;
            position: absolute;
            top: 0;
            right: -100%;

        }
    }


    &_loaded {
        opacity: 1 !important;
        right: 0 !important;
    }
}


.black {
    & * { color: #141414}
}
.white {
    & * { color: #ffffff
    }
}
