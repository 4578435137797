.wrapper {
    padding: 0;
    overflow: hidden;
    cursor: pointer;
}

.header {
    position: absolute;
    top: 10px;
    left: -10px;
}