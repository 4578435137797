.plane {
    & * {
        color: #ffffff !important;
        stroke: #ffffff !important;
        fill: #ffffff !important;
        background-color: transparent !important;
    }

    & div svg defs > g {
        display: none !important;
    }
}