.classic {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 1rem;
    text-align: center;
    & p {
        font-size: 2rem;
        font-weight: 600;
        color: white;

    }

    & img {
        width: 120px;
        height: 120px;
    }
}