.game {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10px;


    &_time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        & > div {
            width: 70%;
            height: 70px;
            border-radius: 20px;
        }
    }

    &_btn {
        width: 100%;
        height: 50px;
        border-radius: 20px;

    }
}