.modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    &_text {
        text-align: center;
        & > p:nth-of-type(1) {
            font-size: 2rem;
            font-weight: 600;
        }
        & span {
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }
    }

    &_btn {
        width: 100%;
        & > button {
            width: 100%;
        }
    }

}
