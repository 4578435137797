.promocode {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & input, & button {
        height: 100%;
        width: 48%;
        border-radius: 16px;
    }

    & input {
        background: transparent;
        border: 1px solid #6689fd;
        color: #fff;
        padding: 0 10px;
        &::placeholder {
            opacity: .5;
        }

    }
}