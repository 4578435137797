.game {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.flame {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    //background: radial-gradient(56% 81.48% at 50% 100%, rgba(201, 196, 255, 0.4) 0%, rgba(0, 60, 255, 0) 100%);

}
