@import "src/styles/vars";
.action {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    margin-top: 20px;
    gap: 20px;
    &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        //background-color:transparent;
        width: 100%;
        height: 50px;
        border-radius: 15px;

        position: relative;
        z-index: 1;

        background: linear-gradient(
                        to right,
                        rgba(59, 73, 221, 0) 0%,
                        rgba(59, 73, 221, 0) 20%,
                        rgba(59, 73, 221, 0.15) 35%,
                        rgba(0, 170, 255, 0.25) 50%,
                        rgba(59, 73, 221, 0.15) 65%,
                        rgba(59, 73, 221, 0) 80%,
                        rgba(59, 73, 221, 0) 100%
        );





        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 16px;
            padding: 1px;
            background: linear-gradient(90deg, rgba(103, 138, 255, 0.8) 0%, rgba(4, 2, 21, 0) 50%, rgba(103, 138, 255, 0.8) 100%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: 16px;
            background-color: transparent;
            backdrop-filter: blur(5px);
            z-index: -2;
        }
        & p {
            color: #fff;
        }

    }
}
