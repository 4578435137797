@import "src/styles/vars";

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 2rem;

    &_title, &_desc {
        font-size: 1.5rem;
        opacity: .6;
        font-weight: 300;
        color: white;
    }
}

.timer {
    display: flex;
    gap: 1rem;
    & p {
        color: white;
    }

    &_segment {
        display: flex;
        align-items: flex-end;
        & p:nth-child(1) {
            line-height: .8;
            font-weight: 400;
            font-size: 5rem;
        }
    }
}

.cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    * p {
        color: white;
    }
    &_item {
        width: 45%;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 10px;
        height: 80px;

        & p, & pre {
            font-family: inherit;
            line-height: 1.2;
        }
    }

    &_item_1 {
        display: flex;
        align-items: center;
        //background-color: rgba(16, 12, 45, 0);
        //backdrop-filter: blur(3px);
        border-radius: 16px;
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 16px;
            padding: 1px;
            background: linear-gradient(90deg, #678AFF 0%, rgba(4, 2, 21, 0) 50%, #678AFF 100%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: -1;
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            border-radius: 15px;
            background-color: rgba(16, 12, 45, 0.5);
            backdrop-filter: blur(5px);
            z-index: -2;
        }

    }
    &_item_2 {
        background: linear-gradient(180deg, #6F91FF 0%, #C261CA 100%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        & pre {
            text-align: start;
        }
        & svg {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

.referer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s border, .3s background-color;
    gap: 10px;

    &_copy {
        width: 100%;
        height: 50px;
        border-radius: 16px;
        border: 1px solid $primary_color;
        display: flex;
        align-items: center;
        &__link {
            width: fit-content;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 3px;
            height: 100%;
            & * {
                color: #9b9aa1;
            }
            & p {
                user-select: text;
                font-size: .7rem;
                white-space: nowrap;
            }
        }

        &__divider {
            width: 1px;
            height: 100%;
            background-color: $primary_color;
        }
    }

    &_main__btn {
        width: 100%;
        border-radius: 16px;
        background-color: $primary_color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 40px;

        transition: .1s;
        & p {
            color: white;
        }

        &:hover {
            background-color: #0063d5;
        }
        &:active {
            background-color: #0079fd;

        }


    }

    &_copy__btn {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & svg {
            color: $primary_color;
        }
    }

}
