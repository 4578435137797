@import "src/styles/_vars.scss";

.main {
    width: 100vw;
    min-height: 100vh;
}

.light {
    &.main {
        background-color: white;
    }
}

.dark {
    &.main {
        background-color: $dark-primary-color;
    }
}
