@import "src/styles/vars";

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    & * {
        color: white;
    }
    & p {
        font-size: 1rem;
    }
}

.daily {
    background: radial-gradient(56% 81.48% at 50% 100%, #C9C4FF 0%, #FF9D00 100%);
    border-radius: 16px;
    width: 100%;
    height: 50px;
    box-shadow: 0px 4px 28px 0px rgba(255, 157, 0, 0.5);
    position: relative;
    overflow: hidden;
    z-index: 1;

    &_gifts {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -2;
        & p {

        }
        & img {
          width: 100px;
        }
        & img:nth-of-type(1) {
            position: absolute;
            top: 0;
            left:0;
        }
        & img:nth-of-type(2) {
            position: absolute;
            top: 0;
            right:0;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        padding: 1px;
        background: linear-gradient(90deg, #ffffff 0%, rgba(4, 2, 21, 0) 50%, #f6f6f6 100%);
        -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-radius: 15px;
        background-color: transparent;
        z-index: -2;
    }
}

.promo {
    background-color: $primary-color;
    border-radius: 16px;
    width: 100%;
    height: 40px;
}
