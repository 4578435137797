@import "src/styles/vars";

.body {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .7rem;

    &_tasks {
        display: flex;
        flex-direction: column;


        gap: 10px;
        //& > div:not(:last-child) {
        //    position: relative;
        //
        //
        //    &::after {
        //        content: "";
        //        position: absolute;
        //        bottom: 0;
        //        left: 50%;
        //        transform: translateX(-50%);
        //        width: 90%;
        //        border-radius: 1px;
        //        height: 1px;
        //        background-color: rgba(104, 104, 104, 0.29);
        //    }
        //}

        &__row {
            width: 100%;
            height: 80px;
            background-color: $dark-selected-primary-background;
            border-radius: 16px;
            cursor: pointer;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            &-title {
                color: white;
                font-weight: 600;
            }
            &-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
            }
            &-award {
                display: flex;
                align-items: center;
                gap: 5px;
                &, & p {
                    color: gold;
                    font-size: .7rem;
                }

            }
            &-img {
                width: 50px;
                border-radius: 10px;
                overflow: hidden;
            }
            &-lottie {
                & > div {
                    width: 50px !important;
                    height: 50px !important;
                }
            }
        }
    }

    &_special {
        display: flex;
        flex-direction: column;


        &__box {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            height: 100%;
        }

        &__row {
            background-color: $dark-selected-primary-background;
            border-radius: 16px;
            width: 100%;
            height: fit-content;
            cursor: pointer;
            padding: 10px 20px;
            position: relative;
            z-index: 1;
            &-title {
                color: white;
                font-weight: 600;
            }
            &-info {
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
                width: 80%;
            }
            &-award {
                display: flex;
                align-items: center;
                gap: 5px;
                &, & p {
                    color: gold;
                    font-size: .7rem;
                }

            }
            &-img {
                width: 50px;
                border-radius: 10px;
                overflow: hidden;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 16px;
                padding: 1px;
                z-index: 0;
                background: linear-gradient(90deg, #be7505 0%, rgba(4, 2, 21, 0) 50%, #be7505 100%);
                -webkit-mask:
                        linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                mask-composite: exclude;
            }
            &::after {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                right: 1px;
                bottom: 1px;
                border-radius: 15px;
                background-color: transparent;
                z-index: -2;
            }

        }

        &__footer {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            width: 100%;
            & button {
                width: 100%;
                border-radius: 20px;
                background-color: white;
                height: 30px;
                z-index: 2;
            }

            & p {
                color: white;
                opacity: .5;
                font-size: .7rem;
                font-weight: 100;
            }

        }
    }
}

.divider {
    & span {
        display: flex;
        align-items: center;
        gap:5px;
        opacity: .5 !important;
        & * {
            font-size: .8rem;
        }
        & svg, & svg * {
            width: 1.2rem;
        }
    }

}
