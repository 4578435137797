.sidebar {
    &_mask {
        backdrop-filter: blur(5px);
    }

    &_content {
        & * {
            color: white;
        }
    }
    &_wrapper {
        min-height: 70vh;
       // display: flex;
       // flex-direction: column;
        height: fit-content !important;
    }
    &_body {
        bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    position: relative;


    &_title {
        font-size: 2rem;
        text-align: center;
        font-weight: 600;
    }


    &_img {
        border-radius: 20px !important;
        width: 150px !important;
        height: 150px !important;
        &__lottie {
            & > div {
                width: 150px !important;
                height: 150px !important;
            }
        }    }

    &_award {
        display: flex;
        align-items: center;
        gap: 5px;
        &, & p {
            color: gold;
            font-size: 1rem;
        }

    }

    &_action {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        & button {
            width: 100%;
            height: 45px;
        }

        & button:nth-child(2) {
            background-color: #1f2732;
            color: #3b6ebd;

            &:hover {
                background-color: #26303e;
            }

        }
    }


}
