$dark-primary-color: #040215;
$primary_color: #6689fd;
$dark-secondary-color: #09061e;

$light-selected-primary-color: rgb(22, 119, 255);
$light-selected-primary-background: rgb(217 233 255 / 66%);

$dark-selected-primary-background: rgb(16, 13, 48);
$dark-selected-primary-color: #ffffff;

$dark-selected-casino-primary-color: #be3bdb;

$dark-global-background: #19191a;
$dark-wrappers-background: #232324;
$dark-selected-background: #4f4f50;
$dark-hover-background: #666667;
$dark-contrast-background: #2d2d2d;


$center-padding: 10px
