@import "src/styles/vars";
.sidebar {
    &_mask {
        backdrop-filter: blur(5px);
    }

    &_content {
        background-color: #141414 !important;
        & * {
            color: white;
        }
    }
    &_wrapper {
        min-height: 70vh;
        // display: flex;
        // flex-direction: column;
        height: fit-content !important;
    }
    &_body {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;

    &_title {
        font-size: 2rem;
    }

    &_img {
        border-radius: 20px;
    }

    &_item {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border-radius: 10px;
        border: 1px solid #b468d1;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        cursor: pointer;

        &__cost {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        &__gif {
            width: 24px;
        }
    }


}
